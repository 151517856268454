// 规则列表
const getRuleListUrl = `/gateway/hc-device/iot/rule/list`;
// 新增规则
const addRuleUrl = `/gateway/hc-device/iot/rule/submit`;
// 新增告警信息
const addWarningUrl = `/gateway/hc-device/iot/rule/warning/submit`;
// 删除规则
const deleteRuleUrl = (url) => {
  return `/gateway/hc-device/iot/rule/${url.ruleId}/delete`;
};
// 获取单个规则的信息
const ruleDetailUrl = (url) => {
  return `/gateway/hc-device/iot/rule/${url.ruleId}/detail`;
};
// 获取单个告警信息
const warningDetailUrl = (url) => {
  return `/gateway/hc-device/iot/rule/${url.ruleId}/warning/detail`;
};
// 产品列表
const productListUrl = `/gateway/hc-device/iot/product/list`;
// 产品属性列表列表
const productAttrListUrl = `/gateway/hc-device/iot/product/attr/list`;
//产品下拉列表 category  （类别）code=auth  （认证）code=net（联网）code=node（节点类型） code=operator（符号）
const proAllListUrl = `/gateway/blade-system/dict/list`;
//工单类型/事件类型列表
const workOrderTypeListUrl = `/gateway/hc-serve/manageapi/snapshot/workOrderTypeList`;
// 更新规则状态
const updateRuleStatusUrl = `/gateway/hc-device/iot/rule/update`;
// 获取工单自主提交人列表
const getIndependentPublisherUrl = `/gateway/hc-serve/manageapi/snapshot/getIndependentPublisher`;
// 获取事件内容下拉框
const getEventContentList = `/gateway/blade-system/dict/list?code=issue_type`;
// 提交
const submitEventConfig = `/gateway/hc-device/iot/rule/issue/submit`;
//物联网产品列表
const getDeviceProList = `/gateway/hc-device/iot/product/list`;
export {
  getRuleListUrl,
  addRuleUrl,
  addWarningUrl,
  deleteRuleUrl,
  ruleDetailUrl,
  warningDetailUrl,
  productListUrl,
  productAttrListUrl,
  proAllListUrl,
  workOrderTypeListUrl,
  updateRuleStatusUrl,
  getIndependentPublisherUrl,
  getEventContentList,
  submitEventConfig,
  getDeviceProList
};
